import { useEffect, useMemo, useState } from 'react';
import {
    ClaimRequestNomaRepository,
    ClaimRequestStatus,
    DiagnosisNomaRepository,
    MissingInfoCategory,
    Note,
    NoteScope,
    SymptomNomaRepository,
    UseAsyncStatus,
    useClaimRequest as useClaimRequestWithRepository,
    useClaimRequestStatus,
    useClinicalHistory,
    useContractIdsActiveOnClaimRequest as useContractIdsActiveOnClaimRequestWithRepository,
    useDiagnosis as useDiagnosisWithRepository,
    useFraudAlerts,
    useHref,
    useNotes,
    useReservation as useReservationWithRepository,
    useSymptoms as useSymptomsWithRepository,
} from '@barkibu/noma-commons';
import { useIncidentDetails } from '@barkibu/noma-data-entry';
import VetViewPage from './VetViewPage';
import { useAssessment as useAssessmentWithRepository } from '../../../application/assessment/useAssessment';
import { Assessment } from '../../../domain/assessment/Assessment';
import { AssessmentNomaRepository } from '../../../infrastructure/assessment/AssessmentNomaRepository';

export interface VetViewPageContainerProps {
    claimRequestKey: string;
}

const VetViewPageContainer = ({ claimRequestKey }: VetViewPageContainerProps) => {
    const useAssessment = useMemo(() => useAssessmentWithRepository(AssessmentNomaRepository), []);
    const useClaimRequest = useMemo(() => useClaimRequestWithRepository(ClaimRequestNomaRepository), []);
    const useSymptoms = useMemo(() => useSymptomsWithRepository(SymptomNomaRepository()), []);
    const useDiagnosis = useMemo(() => useDiagnosisWithRepository(DiagnosisNomaRepository()), []);
    const useReservation = useMemo(() => useReservationWithRepository(ClaimRequestNomaRepository), []);
    const useContractIdsActiveOnClaimRequest = useMemo(
        () => useContractIdsActiveOnClaimRequestWithRepository(ClaimRequestNomaRepository),
        []
    );
    const { toCoverages, toClaimInbox, toDataEntry } = useHref();
    const [assessment, setAssessment] = useState<Assessment | undefined>();
    const { claimRequestDetails, updateCauseOfLoss, updateDateOfLoss, reassignPolicy, duplicateClaimRequest } =
        useClaimRequest(claimRequestKey);
    const { incidentDetails, incidentDetailsFetchStatus } = useIncidentDetails(claimRequestKey);
    const { clinicalHistory } = useClinicalHistory(claimRequestDetails?.policyIdentifier);
    const { stealReservation } = useReservation();
    const { hasStatusReservable, markStatusAsMissingInfo, markStatusAsCreated } =
        useClaimRequestStatus(claimRequestKey);

    const {
        assessment: assessmentValue,
        assessmentFetchStatus,
        addDiagnosis,
        deleteDiagnosis,
        addSymptom,
        deleteSymptom,
        syncStatus,
        syncError,
    } = useAssessment({
        assessmentId: claimRequestDetails?.assessmentId,
        claimRequestId: claimRequestDetails?.claimRequestKey,
    });

    const { symptoms } = useSymptoms();
    const { diagnosis } = useDiagnosis();
    const { notes, createNote, deleteNote } = useNotes(NoteScope.CLAIM_REQUEST, claimRequestKey);
    const { fraudAlerts } = useFraudAlerts(claimRequestKey);
    const { contractIdsActiveOnClaimRequest } = useContractIdsActiveOnClaimRequest(claimRequestKey);
    const [loading, setLoading] = useState(true);
    const [conversionError, setConversionError] = useState<Error>();
    const [currentUserReservation, setCurrentUserReservation] = useState(claimRequestDetails?.userReservation);

    useEffect(() => {
        if (claimRequestKey) {
            const assessmentCreated = assessmentValue != null;

            const assessmentLoaded = assessmentFetchStatus === UseAsyncStatus.SUCCESS && assessmentCreated;

            if (assessmentLoaded) {
                setAssessment(assessmentValue);
            }

            setLoading(!assessmentLoaded);
        }
    }, [assessmentValue, assessmentFetchStatus, incidentDetailsFetchStatus]);

    useEffect(() => {
        setCurrentUserReservation(claimRequestDetails?.userReservation);
    }, [claimRequestDetails]);

    const onMarkMissingInfo = async (missingInfoCategories: MissingInfoCategory[], noteValue: string) => {
        if (missingInfoCategories.length == 0) return;
        setLoading(true);
        try {
            await markStatusAsMissingInfo(missingInfoCategories, noteValue);
            toClaimInbox();
        } catch (e) {
            if (e instanceof Error) setConversionError(e);
            setLoading(false);
        }
    };
    const goToCostLines = () => toCoverages(claimRequestKey);

    const moveBackToDataentry = async () => {
        try {
            await markStatusAsCreated();
            toDataEntry(claimRequestKey);
        } catch (e) {
            if (e instanceof Error) setConversionError(e);
            setLoading(false);
        }
    };

    const handleStealReservation = () => {
        stealReservation(claimRequestKey).then((user) => setCurrentUserReservation(user));
    };

    const handleDuplicateClaimRequest = async () => {
        const duplicatedClaimRequest = await duplicateClaimRequest();
        toDataEntry(duplicatedClaimRequest.key);
    };

    return (
        <VetViewPage
            claimRequestDetails={claimRequestDetails}
            incidentDetails={incidentDetails}
            clinicalHistory={clinicalHistory}
            symptomOptions={symptoms}
            diagnosisOptions={diagnosis}
            assessment={assessment}
            assessmentUserReservation={currentUserReservation}
            notes={notes}
            fraudAlerts={fraudAlerts}
            loading={loading}
            setLoading={setLoading}
            onAddDiagnosis={addDiagnosis}
            onDeleteDiagnosis={deleteDiagnosis}
            onAddSymptom={addSymptom}
            onDeleteSymptom={deleteSymptom}
            onUpdateCauseOfLoss={updateCauseOfLoss}
            onAddNote={createNote}
            onDeleteNote={(note: Note) => deleteNote(note.key)}
            onUpdateDateOfLoss={updateDateOfLoss}
            isSyncing={syncStatus == UseAsyncStatus.PENDING}
            syncError={syncError || conversionError}
            readOnly={incidentDetails?.status.description !== ClaimRequestStatus.READY_FOR_VET_ANALYSIS}
            onMarkMissingInfo={onMarkMissingInfo}
            onContinue={goToCostLines}
            onMoveBackToDataentry={moveBackToDataentry}
            onStealReservation={
                incidentDetails && hasStatusReservable(incidentDetails.status.description)
                    ? handleStealReservation
                    : undefined
            }
            onChangePolicy={reassignPolicy}
            onDuplicateClaimRequest={handleDuplicateClaimRequest}
            contractIdsActiveOnClaimRequest={contractIdsActiveOnClaimRequest}
        />
    );
};

export default VetViewPageContainer;
