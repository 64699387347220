import { useEffect, useState } from 'react';
import { ClaimRequestType } from '../../../domain/entities';
import {
    MainSupportingDocumentTypes,
    MissingInfoCategory,
    SupportingDocumentType,
    YesNoValue,
} from '../../../domain/values';
import { defaultCountryCode } from '../../../domain/values/CountryCode';
import { Button, Dropdown, GroupedData, Modal, NoteInput } from '../../atoms';
import { useVetTypeform } from '../../hooks/useVetTypeform/useVetTypeform';
import { MissingInfoCategorySelector } from '../../molecules';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './mark-incident-missing-info-status-modal.scss';

export interface MarkIncidentMissingStatusModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (missingInfoCategories: MissingInfoCategory[], noteValue: string) => void;
    preselectedMissingInfoCategories?: MissingInfoCategory[];
    claimRequestType?: ClaimRequestType;
    hideNoteInput?: boolean;
    countryCode?: string;
    petName?: string;
    claimRequestKey?: string;
    claimRequestDate?: Date;
}

const MarkIncidentMissingInfoStatusModal = ({
    hideNoteInput,
    isOpen,
    onClose,
    onSave,
    preselectedMissingInfoCategories,
    claimRequestType = ClaimRequestType.HEALTH,
    countryCode,
    petName,
    claimRequestKey,
    claimRequestDate,
}: MarkIncidentMissingStatusModalProps) => {
    const { t } = useModuleTranslation();
    const { replaceVetTypeformTokens, getVetFormTag, getPrintableVetFormLink } = useVetTypeform();
    const defaultMissingInfoOptions = [...Object.values(MainSupportingDocumentTypes), SupportingDocumentType.OTHER];
    const [missingInfoValue, setMissingInfoValue] = useState<YesNoValue>();
    const [missingInfoCategories, setMissingInfoCategories] = useState<MissingInfoCategory[]>([]);
    const [noteValue, setNoteValue] = useState<string>('');
    const [missingInfoCategoryOptions, setMissingInfoCategoryOptions] =
        useState<SupportingDocumentType[]>(defaultMissingInfoOptions);

    const handleClose = () => {
        setMissingInfoValue(undefined);
        setMissingInfoCategories([]);
        setMissingInfoCategoryOptions(defaultMissingInfoOptions);
        setNoteValue('');
        onClose();
    };

    const templateVetReportPostProcessing = (claimRequestKey: string, countryCode: string) => {
        const vetReportTokens = new Map([
            [
                '${{vetForm}}',
                getVetFormTag(
                    t('vet_form.survey_label', {
                        ns: 'missing_info',
                        lng: countryCode.toLowerCase(),
                    }),
                    claimRequestKey,
                    countryCode
                ),
            ],
            [
                '${{printableVetForm}}',
                getPrintableVetFormLink(
                    t('vet_form.printable_label', {
                        ns: 'missing_info',
                        lng: countryCode.toLowerCase(),
                    }),
                    countryCode
                ),
            ],
        ]);
        return replaceVetTypeformTokens(noteValue, vetReportTokens);
    };

    const handleSave = () => {
        if (missingInfoCategories.includes(SupportingDocumentType.VET_FORM as never)) {
            onSave(missingInfoCategories, templateVetReportPostProcessing(claimRequestKey!, countryCode!));
        } else {
            onSave(missingInfoCategories, noteValue);
        }
    };

    const vetFormToggle = (newMissingInfoCategory: MissingInfoCategory) => {
        if (newMissingInfoCategory == SupportingDocumentType.VET_FORM) {
            if (missingInfoCategories.includes(SupportingDocumentType.VET_FORM as MissingInfoCategory))
                setMissingInfoCategoryOptions(defaultMissingInfoOptions);
            else {
                setMissingInfoCategoryOptions([SupportingDocumentType.VET_FORM]);
                setMissingInfoCategories([SupportingDocumentType.VET_FORM as MissingInfoCategory]);
                setNoteValue(
                    t('vet_form.template', {
                        ns: 'missing_info',
                        lng: countryCode ? countryCode?.toLowerCase() : defaultCountryCode,
                        petName,
                        claimRequestDate: claimRequestDate?.toLocaleDateString(),
                    })
                );
            }
        }
    };

    const handleMissingInfoCategorySelection = (newMissingInfoCategory: MissingInfoCategory) => {
        const textMissingInfoCategory = (newMissingInfoCategory as string).toLowerCase();
        const missingInfoDetail = t(textMissingInfoCategory, {
            ns: 'missing_info',
            lng: countryCode ? countryCode.toLowerCase() : 'es',
            petName,
        });

        if (missingInfoCategories.includes(newMissingInfoCategory)) {
            setMissingInfoCategories(missingInfoCategories.filter((category) => category != newMissingInfoCategory));
            setNoteValue(noteValue.replace(missingInfoDetail, ''));
        } else {
            setMissingInfoCategories([...missingInfoCategories, newMissingInfoCategory]);
            missingInfoDetail != textMissingInfoCategory &&
                setNoteValue((noteValue + '\n\n' + missingInfoDetail).trim());
        }
        vetFormToggle(newMissingInfoCategory);
    };
    useEffect(() => {
        preselectedMissingInfoCategories?.forEach((it) => setMissingInfoCategories([...missingInfoCategories, it]));
    }, [preselectedMissingInfoCategories]);

    useEffect(() => {
        preselectedMissingInfoCategories?.forEach((it) => handleMissingInfoCategorySelection(it));

        missingInfoValue == YesNoValue.NO && setMissingInfoCategories([]);
    }, [missingInfoValue]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            title={t('missing_info.title')}
            className="update-incident-status-modal"
        >
            <div className="update-incident-status-modal__body">
                <div className="update-incident-status-modal__content">
                    <div className="missing-info-selector-container">
                        <MissingInfoCategorySelector
                            missingInfoCategories={missingInfoCategories}
                            onMissingInfoCategorySelectedHandler={handleMissingInfoCategorySelection}
                            options={missingInfoCategoryOptions}
                        />
                    </div>

                    {!hideNoteInput && (
                        <div>
                            <span className="label">{t('missing_info.note_label')}</span>
                            {countryCode &&
                                petName &&
                                !missingInfoCategories.includes(
                                    SupportingDocumentType.VET_FORM as MissingInfoCategory
                                ) && (
                                    <div className="selector">
                                        <Dropdown
                                            label={t(`missing_info.templates`)}
                                            values={
                                                t(`${claimRequestType.toString().toLowerCase()}.templates`, {
                                                    ns: 'missing_info',
                                                    lng: countryCode.toLowerCase(),
                                                    returnObjects: true,
                                                    petName,
                                                }) as GroupedData<string>[]
                                            }
                                            handleOptionClicked={(option) =>
                                                setNoteValue(option.value + '\n\n' + noteValue)
                                            }
                                            name={'missingInfoTemplate'}
                                        ></Dropdown>
                                    </div>
                                )}
                            <NoteInput
                                value={noteValue}
                                label={t('missing_info.add_note')}
                                onValueChange={setNoteValue}
                            />
                        </div>
                    )}
                </div>
                <div className="update-incident-status-modal__actions">
                    <Button
                        disabled={missingInfoCategories.length == 0}
                        text={t('missing_info.save')}
                        accessibilityText={t('missing_info.save_description')}
                        onClick={() => handleSave()}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default MarkIncidentMissingInfoStatusModal;
