import { ClaimRequestStatus, MissingInfoCategory } from '../../../domain/values';
import { ClaimRequestStatusNomaRepository } from '../../../infraestructure';

const claimRequestNomaRepository = new ClaimRequestStatusNomaRepository();

export const useClaimRequestStatus = (claimRequestKey: string) => {
    const markStatusAsOnHold = async () => {
        await claimRequestNomaRepository.markStatusAsOnHold(claimRequestKey);
    };
    const markStatusAsMissingInfo = async (categories: MissingInfoCategory[], noteValue: string) => {
        await claimRequestNomaRepository.markStatusAsMissingInfo(claimRequestKey, {
            documents: categories,
            noteValue,
        });
    };

    const markStatusAsManuallyClosed = async (closeReason?: string, explanationForParent?: string) => {
        await claimRequestNomaRepository.markStatusAsManuallyClosed(claimRequestKey, closeReason, explanationForParent);
    };

    const markStatusAsCreated = async () => {
        await claimRequestNomaRepository.markStatusAsOnCreated(claimRequestKey);
    };

    const hasStatusReservable = (claimRequestStatus: ClaimRequestStatus) => {
        switch (claimRequestStatus) {
            case ClaimRequestStatus.CREATED:
            case ClaimRequestStatus.MISSING_INFO:
            case ClaimRequestStatus.ON_HOLD:
            case ClaimRequestStatus.READY_FOR_VET_ANALYSIS:
            case ClaimRequestStatus.PENDING_DATA_REVIEW:
            case ClaimRequestStatus.PENDING_MUNICH_RE:
                return true;
            default:
                return false;
        }
    };

    return {
        markStatusAsOnHold,
        markStatusAsMissingInfo,
        markStatusAsManuallyClosed,
        markStatusAsCreated,
        hasStatusReservable,
    };
};
