import React from 'react';
import { stylesMode } from '../../styles';
import { MoreIcon as More, SuccessIcon as Success } from '../icons';
import './chip.modules.scss';

type BaseChipProps = {
    type: ChipType;
    text: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    accessibilityText?: string;
    className?: string;
    color?: string;
    style?: React.CSSProperties;
};

type RequiredOnClick = Required<Pick<BaseChipProps, 'onClick'>>;

export type ChipProps = BaseChipProps &
    (
        | {
              type: ChipType.ERROR | ChipType.SUCCESS | ChipType.WARNING | ChipType.NEUTRAL;
              icon?: React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;
          }
        | ({
              type: ChipType.CHECK | ChipType.OPTION;
              checked: boolean;
          } & RequiredOnClick)
        | ({
              type: ChipType.ACTIONABLE | ChipType.FILTER;
              icon?: React.FC<React.HTMLAttributes<HTMLOrSVGElement>>;
          } & RequiredOnClick)
    );

enum ChipMode {
    SELECTED = 'selected',
    UNSELECTED = 'unselected',
}

export enum ChipType {
    SUCCESS = 'success',
    ERROR = 'error',
    CHECK = 'check',
    OPTION = 'option',
    WARNING = 'warning',
    ACTIONABLE = 'actionable',
    NEUTRAL = 'neutral',
    FILTER = 'filter',
}

const Chip = (props: ChipProps) => {
    const canHaveAnIcon =
        props.type === ChipType.SUCCESS ||
        props.type === ChipType.ERROR ||
        props.type === ChipType.WARNING ||
        props.type === ChipType.ACTIONABLE ||
        props.type === ChipType.FILTER;
    const hasSelectableType = props.type === ChipType.CHECK || props.type === ChipType.OPTION;
    const classNames = stylesMode({
        with: 'chip',
        prefix: 'chip',
        modes: [
            props.type,
            props.className,
            hasSelectableType ? `${props.type}--${props.checked ? ChipMode.SELECTED : ChipMode.UNSELECTED}` : undefined,
            hasSelectableType || props.onClick ? 'enabled' : 'disabled',
        ],
    });

    const Icon =
        props.type === ChipType.CHECK ? (props.checked ? Success : More) : canHaveAnIcon ? props.icon : undefined;

    const componentProperties = {
        className: props.color != null ? `chip-color--${props.color} ` + classNames : classNames,
        style: props.style,
        onClick: props.onClick,
    } as React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

    if (hasSelectableType) {
        componentProperties['aria-selected'] = props.checked;
    }

    return (
        <div {...componentProperties} aria-label={props.accessibilityText} title={props.accessibilityText}>
            {Icon != null && (
                <div className="chip-icon--container">
                    <Icon className="chip-icon" aria-label="Chip icon" />
                </div>
            )}
            <span>{props.text}</span>
        </div>
    );
};

export default Chip;
