import { MissingInfoCategory, SupportingDocumentType } from '../../../domain/values';
import { Chip, ChipType } from '../../atoms';
import { useModuleTranslation } from '../../utils/useModuleTranslation';

export interface MissingInfoCategorySelectorProps {
    missingInfoCategories: MissingInfoCategory[];
    onMissingInfoCategorySelectedHandler: (newMissingInfoCategory: MissingInfoCategory) => void;
    options: SupportingDocumentType[];
}

const MissingInfoCategorySelector = ({
    missingInfoCategories,
    onMissingInfoCategorySelectedHandler,
    options,
}: MissingInfoCategorySelectorProps) => {
    const { t } = useModuleTranslation();

    return (
        <>
            <span className="label">{t(`missing_info.which`)}</span>
            <div className="missing-info-category-selector">
                {options.map((description) => (
                    <Chip
                        key={description}
                        type={ChipType.OPTION}
                        text={t(`missing_info.type.${description}`)}
                        checked={missingInfoCategories.includes(description as MissingInfoCategory)}
                        onClick={() => onMissingInfoCategorySelectedHandler(description as MissingInfoCategory)}
                    />
                ))}
            </div>
        </>
    );
};

export default MissingInfoCategorySelector;
