import { useEffect, useMemo, useState } from 'react';
import {
    ClaimRequestNomaRepository,
    MissingInfoCategory,
    Note,
    NoteScope,
    SupportingDocumentDeletionReason,
    UseAsyncStatus,
    useClaimRequest as useClaimRequestWithRepository,
    useClaimRequestStatus,
    useClinicalHistory,
    useContractIdsActiveOnClaimRequest as useContractIdsActiveOnClaimRequestWithRepository,
    useFraudAlerts,
    useHref,
    useNotes,
    useReservation as useReservationWithRepository,
} from '@barkibu/noma-commons';
import { IncidentDetails as IncidentDetailsView } from './IncidentDetails';
import { useIncidentDetails } from './useIncidentDetails';
import { SupportingDocument } from '../../../domain/entities/supporting-document';

export interface IncidentDetailsContainerProps {
    claimRequestKey: string;
}

const IncidentDetailsContainer = ({ claimRequestKey }: IncidentDetailsContainerProps) => {
    const useClaimRequest = useMemo(() => useClaimRequestWithRepository(ClaimRequestNomaRepository), []);
    const useReservation = useMemo(() => useReservationWithRepository(ClaimRequestNomaRepository), []);
    const useContractIdsActiveOnClaimRequest = useMemo(
        () => useContractIdsActiveOnClaimRequestWithRepository(ClaimRequestNomaRepository),
        []
    );
    const {
        incidentDetails,
        incidentDetailsFetchStatus,
        createSupportingDocument,
        updateSupportingDocument,
        updateSupportingDocumentIsNewlyAddedByParent,
        deleteSupportingDocument,
    } = useIncidentDetails(claimRequestKey);

    const { toClaimInbox, toInvoiceDigitisation, toVetView, toDataEntry } = useHref();
    const { fraudAlerts } = useFraudAlerts(claimRequestKey);
    const { hasStatusReservable, markStatusAsOnHold, markStatusAsMissingInfo, markStatusAsManuallyClosed } =
        useClaimRequestStatus(claimRequestKey);
    const {
        claimRequestDetails,
        updateCauseOfLoss,
        updateDateOfLoss,
        updateIban,
        updateEmail,
        deleteClaimRequest,
        duplicateClaimRequest,
        reassignPolicy,
    } = useClaimRequest(claimRequestKey);
    const { stealReservation } = useReservation();
    const { clinicalHistory } = useClinicalHistory(claimRequestDetails?.policyIdentifier);
    const { notes, createNote, deleteNote } = useNotes(NoteScope.CLAIM_REQUEST, claimRequestKey);
    const { contractIdsActiveOnClaimRequest } = useContractIdsActiveOnClaimRequest(claimRequestKey);

    const [loading, setLoading] = useState(false);
    const [currentUserReservation, setCurrentUserReservation] = useState(incidentDetails?.userReservation);

    useEffect(() => {
        if (claimRequestKey) {
            setLoading(!(incidentDetailsFetchStatus == UseAsyncStatus.SUCCESS));
            setCurrentUserReservation(incidentDetails?.userReservation);
        }
    }, [incidentDetailsFetchStatus]);

    const handleCreateDocument = async (documentToCreate: SupportingDocument) => {
        const createSupportingDocumentDto = {
            type: documentToCreate.type!,
            name: documentToCreate.name,
            location: documentToCreate.location!,
        };
        if (claimRequestKey) await createSupportingDocument(claimRequestKey, createSupportingDocumentDto);
    };
    const handleUpdateDocument = async (documentToUpdate: SupportingDocument) => {
        const updateSupportingDocumentDto = {
            type: documentToUpdate.type!,
            name: documentToUpdate.name,
            location: documentToUpdate.location!,
            deleted: documentToUpdate.deleted!,
        };
        if (incidentDetails?.incidentKey)
            await updateSupportingDocument(documentToUpdate.key!, updateSupportingDocumentDto);
    };

    const handleDeleteDocument = async (
        incidentKey: string,
        documentToDelete: SupportingDocument,
        deletionReason: SupportingDocumentDeletionReason
    ) => {
        if (incidentKey) await deleteSupportingDocument(incidentKey, documentToDelete, deletionReason);
    };

    const handleUpdateDocumentIsNewlyAddedByParent = async (incidentKey: string, document: SupportingDocument) => {
        const updateSupportingDocumentIsNewlyAddedByParentDto = {
            isNewlyAddedByParent: false,
        };
        if (incidentKey)
            await updateSupportingDocumentIsNewlyAddedByParent(
                document.key!,
                updateSupportingDocumentIsNewlyAddedByParentDto
            );
    };

    const handleCloseIncident = async (closeReason?: string, explanationForParent?: string) => {
        await markStatusAsManuallyClosed(closeReason, explanationForParent);
        toClaimInbox();
    };

    const handleDeleteClaimRequest = async () => {
        await deleteClaimRequest();
        toClaimInbox();
    };

    const handleDuplicateClaimRequest = async () => {
        const duplicatedClaimRequest = await duplicateClaimRequest();
        toDataEntry(duplicatedClaimRequest.key);
    };

    const handleOnHold = async () => {
        await markStatusAsOnHold();
        toClaimInbox();
        return;
    };
    const handleContinueWithIncident = async (missingInfoCategories: MissingInfoCategory[], noteValue: string) => {
        if (incidentDetails?.readOnly) {
            toVetView(claimRequestKey);
            return;
        }
        if (missingInfoCategories.length > 0) {
            await markStatusAsMissingInfo(missingInfoCategories, noteValue);
            toClaimInbox();
            return;
        }
        await toInvoiceDigitisation(claimRequestKey);
    };
    const handleStealReservation = () => {
        stealReservation(claimRequestKey).then((user) => setCurrentUserReservation(user));
    };

    return (
        <IncidentDetailsView
            claimRequestDetails={claimRequestDetails}
            incidentDetails={incidentDetails}
            clinicalHistory={clinicalHistory}
            userReservation={currentUserReservation}
            notes={notes}
            fraudAlerts={fraudAlerts}
            onMarkAsHoldIncident={handleOnHold}
            onContinueWithIncident={handleContinueWithIncident}
            loading={loading}
            setLoading={setLoading}
            onUpdateCauseOfLoss={updateCauseOfLoss}
            onUpdateDateOfLoss={updateDateOfLoss}
            onUpdateEmail={updateEmail}
            onUpdateIban={updateIban}
            onAddNote={createNote}
            onDeleteNote={(note: Note) => deleteNote(note.key)}
            onCreateDocument={handleCreateDocument}
            onUpdateDocument={handleUpdateDocument}
            onDeleteDocument={handleDeleteDocument}
            onUpdateDocumentIsNewlyAddedByParent={handleUpdateDocumentIsNewlyAddedByParent}
            onChangePolicy={reassignPolicy}
            onCloseIncident={handleCloseIncident}
            onDeleteClaimRequest={handleDeleteClaimRequest}
            onDuplicateClaimRequest={handleDuplicateClaimRequest}
            onStealReservation={
                incidentDetails && hasStatusReservable(incidentDetails.status.description)
                    ? handleStealReservation
                    : undefined
            }
            contractIdsActiveOnClaimRequest={contractIdsActiveOnClaimRequest}
        />
    );
};

export default IncidentDetailsContainer;
